import { Button, Container, Group, Image } from '@mantine/core'
import { useRouter } from 'next/router'
import Layout from '../components/layout/index.js'
import prerenderImportantLocales from '../utils/prerender_locales.js'

const Custom404 = () => {
  const router = useRouter()

  return (
    <Container
      style={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <Image src="error-404.png" alt="404 Error" width={400} height={400} mt={50} />
      <Group position="center" mt={30}>
        <Button variant="outline" onClick={() => router.push('/')}>
          Inicio
        </Button>
        <Button onClick={() => router.back()}>Página Anterior</Button>
      </Group>
    </Container>
  )
}

Custom404.getLayout = function getLayout(page) {
  return <Layout withAutocomplete>{page}</Layout>
}

export async function getStaticProps({ locale }) {
  prerenderImportantLocales(locale)
  return {
    props: {
      messages: (await import(`../locales/${locale.split('-')[0]}/common.json`))
        .default
    }
  }
}

export default Custom404
